import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import { navigate } from 'gatsby';

import LazilyRenderedList from './LazilyRenderedList';
import * as theme from '../css-modules/AlbumPage.module.css';
import { getAlbumTitle } from './AlbumTitle';
import Logo from '../components/Logo';
import { getPhotoUrl } from '../utils/string';
import { CloseIcon } from '../components/CloseIcon';
import classNames from 'classnames';
import { fetchBucketData } from '../utils/s3';

export default function AlbumPage({
  albumName,
  path,
}: {
  albumName?: string;
  path?: string;
}) {
  const [bucketData, setBucketData] = useState<string[] | null>(null);
  const [urlsToRender, setUrlsToRender] = useState<string[]>([]);
  const [urlsToPreload, setUrlsToPreload] = useState<string[]>([]);
  const [isFullPage, setIsFullPage] = useState(false);
  const [currentKey, setCurrentKey] = useState<string | null>(null);
  const fullPageScrollRef = useRef<HTMLDivElement>(null);
  const urls = albumName
    ? bucketData?.map((objectKey) => getPhotoUrl(objectKey))
    : null;

  const handleKeyDown = (event: KeyboardEvent) => {
    if (fullPageScrollRef.current) {
      const scrollAmount = 100;
      console.log(event.key);
      if (event.key === 'ArrowRight') {
        fullPageScrollRef.current.scrollBy({ left: scrollAmount });
      } else if (event.key === 'ArrowLeft') {
        fullPageScrollRef.current.scrollBy({ left: -scrollAmount });
      } else if (event.key === 'Escape') {
        setIsFullPage(false);
      }
    }
  };

  useEffect(() => {
    if (albumName) {
      fetchBucketData({
        Prefix: albumName,
        // Filter out the directory entity, leaving only the image entities
        filterFunc: (key: string | undefined): key is string =>
          !!(key && /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(key)),
      }).then((objectKeys) => {
        setBucketData(objectKeys);
      });
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleStartImagePreload = (urls: string[]): void => {
    setUrlsToPreload(urls);
  };
  const handleImagePreloaded = (url: string): void => {
    setUrlsToRender((urlsToRender) => urlsToRender.concat([url]));
  };
  const handleRecordCurrentKey = (item) => {
    // console.log(item);
  };

  return (
    <>
      {albumName && urls && (
        <div
          className={classNames(theme.AlbumPage, {
            [theme.Shown]: !isFullPage,
          })}
        >
          {albumName && (
            <h1 className={theme.AlbumPageHeader}>
              <Logo
                className={theme.LogoSvg}
                onClick={() => {
                  navigate('/albums');
                }}
              />
              {getAlbumTitle(albumName)}
              <div className={theme.PhotoCount}>{urls.length} Photos</div>
            </h1>
          )}
          {urls.length > 0 && (
            <LazilyRenderedList
              className={theme.ImageList}
              itemClassName={theme.ImageListItem}
              preloadBuffer={window.innerHeight * 5}
              itemDimensionsStyle={{ width: '100%', minHeight: '260px' }}
              llKeyField="data-src"
              shouldKeepItemsRendered
              onRenderItems={handleStartImagePreload}
              handleReportItemsInViewport={handleRecordCurrentKey}
              keyToScroll={!isFullPage ? currentKey : null}
            >
              {urls.map((url) => {
                const shouldRenderUrl = urlsToRender.indexOf(url) > -1;
                return shouldRenderUrl ? (
                  <img
                    tabIndex={0}
                    key={url}
                    src={url}
                    className={classNames(theme.Image, theme.ImageClickable)}
                    onClick={() => {
                      setCurrentKey(url);
                      setIsFullPage(true);
                    }}
                    data-src={url}
                  />
                ) : (
                  <div
                    key={url}
                    className={theme.ImagePlaceholder}
                    data-src={url}
                  />
                );
              })}
            </LazilyRenderedList>
          )}
        </div>
      )}
      {albumName && urls && (
        <div
          className={classNames(theme.AlbumPageFull, {
            [theme.Shown]: isFullPage,
          })}
          ref={fullPageScrollRef}
        >
          <CloseIcon
            className={theme.CloseIcon}
            onClick={() => {
              setIsFullPage(false);
            }}
          />
          <LazilyRenderedList
            horizontal
            useParentAsScrollableAncestor
            className={theme.ImageList}
            itemClassName={theme.ImageListItem}
            preloadBuffer={window.innerWidth * 10}
            itemDimensionsStyle={{
              width: '100vw',
              height: '100%',
            }}
            llKeyField="data-src"
            shouldKeepItemsRendered
            onRenderItems={handleStartImagePreload}
            handleReportItemsInViewport={handleRecordCurrentKey}
            keyToScroll={isFullPage ? currentKey : null}
            style={{ width: `${urls.length * 100}vw` }}
          >
            {urls.map((url) => {
              const shouldRenderUrl = urlsToRender.indexOf(url) > -1;
              return shouldRenderUrl ? (
                <img
                  tabIndex={0}
                  key={url}
                  src={url}
                  className={theme.Image}
                  data-src={url}
                />
              ) : (
                // Placeholder is rendered after LazilyRenderedList determines item should be rendered,
                // but before image is preloaded
                <div
                  key={url}
                  className={theme.ImagePlaceholder}
                  data-src={url}
                />
              );
            })}
          </LazilyRenderedList>
        </div>
      )}

      {/* Preload the first image first */}
      {urls && (
        <link
          className={theme.OffscreenImg}
          rel="preload"
          href={urls[0]}
          key={urls[0]}
          as="image"
          onLoad={() => handleImagePreloaded(urls[0])}
        />
      )}
      {/* Preload the second image */}
      {urls && urls[1] && urlsToRender.length >= 1 && (
        <link
          className={theme.OffscreenImg}
          rel="preload"
          href={urls[1]}
          key={urls[1]}
          as="image"
          onLoad={() => handleImagePreloaded(urls[1])}
        />
      )}
      {/* Preload the rest of the images */}
      {urlsToRender.length >= 2 &&
        urlsToPreload.map((url) => (
          <link
            className={theme.OffscreenImg}
            rel="preload"
            href={url}
            key={url}
            as="image"
            onLoad={() => handleImagePreloaded(url)}
          />
        ))}
    </>
  );
}
